import {
  Box,
  styled,
  Avatar
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 53px;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
); 


function Logo() {
  const theme = useTheme();
  return (
    <LogoWrapper to="/application/projects">
      <img
        src={'/static/images/logo/logo_3.png'} 
        srcSet={'/static/images/logo/logo_3.png'}
        alt={'Logo'}
        loading="lazy"
        style={{
          width: '200px',
          height: '50%'
        }}
      />
    </LogoWrapper>
  );
}

export default Logo;
