import React, { useState } from 'react';
import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Login from 'src/content/login';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  zoomContainer: {
    padding: '1rem',
    backgroundColor: '#f1f1f1',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh'
  },
  zoomContent: {
    fontSize: '2rem',
    color: '#333',
    textAlign: 'center',
  },
}));


function App() {
  const content = useRoutes(router);
  const classes = useStyles();
  const [token, setToken] = useState();

  /*if (!token) {
    return <Login setToken={setToken} />
  }*/



  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {content}
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
