import { FC, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Typography, Grid, Avatar } from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import MunicipalityService from 'src/services/municipality.service';

interface PageTitleProps {
    heading?: string;
    onChange: ({ }) => void
}
const HeaderMenu2: FC<PageTitleProps> = ({ onChange }) => {

    let selectedMunicipality = { value: '', label: '', src: '' };

    if (MunicipalityService.GetSelectedMunicipality() === undefined) {
        const defaultMunicipality = MunicipalityService.DistrictMunicipaity;
        localStorage.setItem("SelectedMunicipality", JSON.stringify(defaultMunicipality));
        selectedMunicipality = defaultMunicipality;
    }
    else {
        selectedMunicipality = MunicipalityService?.Municipalities.find(x => x.value === MunicipalityService.GetSelectedMunicipality());
    }

    const [municipality, setMunicipality] = useState(selectedMunicipality.value);
    const [pageHeader, setHeader] = useState(selectedMunicipality.label);
    const [logo, setLogo] = useState(selectedMunicipality.src);
    const theme = useTheme();

    const HandleChange = (event) => {
        let municipality = MunicipalityService?.Municipalities.find(x => x.value === event.target.value);
        setMunicipality(event.target.value);
        setHeader(municipality.label);
        setLogo(municipality.src);
        onChange(municipality);
    };

    return (
        <Grid
            container
            justifyContent="space-between"
            alignItems="center"
        >
            <Grid item lg={2}>
                <Avatar
                    sx={{
                        mr: 2,
                        width: theme.spacing(5),
                        height: theme.spacing(5)
                    }}
                    variant="rounded"
                    src={logo}
                />
            </Grid>
            <Grid item lg={6}>
                <Typography variant="h4" component="h4" gutterBottom>
                    {pageHeader}
                </Typography>
            </Grid>
            <Grid item lg={4}>
                <TextField
                    select
                    label="Municipality"
                    value={municipality}
                    onChange={HandleChange}
                >
                    {MunicipalityService.Municipalities.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

        </Grid>
    );
};

HeaderMenu2.propTypes = {
    heading: PropTypes.string
};

export default HeaderMenu2;
