import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router'; 
import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout'; 
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);
// Pages 

const Login = Loader(lazy(() => import('src/content/login'))); 

// Dashboards  
const MunicipalityDashboard = Loader(lazy(() => import('src/content/dashboards/Municipality')));

// Applications 

const Projects = Loader(
  lazy(() => import('src/content/applications/Projects'))
);

const IdpProjects = Loader(
  lazy(() => import('src/content/applications/Projects/idp'))
);

const Budget = Loader(
  lazy(() => import('src/content/applications/Budget'))
);

const ViewBudget = Loader(
  lazy(() => import('src/content/applications/Budget/viewBudget'))
);

const AddProject = Loader(
  lazy(() => import('src/content/applications/Projects/addProject'))
);
const ViewProject = Loader(
  lazy(() => import('src/content/applications/Projects/viewProject'))
);
const ServiceProvider = Loader(
  lazy(() => import('src/content/applications/ServiceProvider'))
);
const Users = Loader(
  lazy(() => import('src/content/applications/Users'))
);
const FinancialOverview = Loader(
  lazy(() => import('src/content/applications/FinancialOverview'))
);

const ManagementUserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Login />
      }, {
        path: 'idp-projects',
        element: <IdpProjects />
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      }
    ]
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: 'municipalityDashboard',
        element: <MunicipalityDashboard />
      }
    ]
  },
  {
    path: 'application',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />
      },
      {
        path: 'projects',
        element: <Projects />
      },
      {
        path: 'budget',
        element: <Budget />
      },
      {
        path: 'viewBudget',
        element: <ViewBudget />
      }, {
        path: 'idp',
        element: <IdpProjects />
      },
      {
        path: 'addProject',
        element: <AddProject />
      },
      {
        path: 'viewProject',
        element: <ViewProject />
      },
      {
        path: 'serviceProviders',
        element: <ServiceProvider />
      },
      {
        path: 'users',
        element: <Users /> 
      },
      {
        path: 'userSettings',
        element: <ManagementUserSettings />, 
      },
      {
        path: 'financialOverview',
        element: <FinancialOverview />
      }
    ]
  } 
];

export default routes;
