import http from "../http-common";
import decode from 'jwt-decode'
import { User } from "src/models/user";

const Roles = [
  { value: '1', label: 'Project Manager' },
  { value: '2', label: 'Admin' },
  { value: '3', label: 'General Manager' },
  { value: '4', label: 'Technical Manager' }];

const AUTH_TOKEN_KEY = 'authToken';

async function Login(email, password): Promise<User> {

  var data = {
    email: email,
    password: password
  }

  let user: User;
  await http.post<User>("/login", data).then((response: any) => {
    if (response.status == 200) {
      const myJSON = JSON.stringify(response.data);
      user = JSON.parse(myJSON).user;
    }
  }).catch((e: Error) => {
    console.log(e);
  });
  return user;
};

async function ChangePassword(userId, password): Promise<string> {
  var data = {
    userId: userId,
    password: password
  }

  let newPassword: string;
  await http.post<string>("/password", data).then((response: any) => {
    if (response.status == 200) {
      newPassword = password;
    }
  }).catch((e: Error) => {
    console.log(e);
  });
  return newPassword;
};

const VerifyLogin = (username, password) => {
  return http.get<Array<User>>("/invoice?project_id=" + username);
};


const CreateUser = (data: User) => {
  return http.post<User>("/register", data);
};

async function GetUsers(): Promise<Array<User>> {
  let users = new Array<User>();

  await http.get<Array<User>>("/users").then((response: any) => {
    if (response.status == 200) {
      const myJSON = JSON.stringify(response.data);
      users = JSON.parse(myJSON).users;
    }
  }).catch((e: Error) => {
    console.log(e);
  });

  return users;
}
function GetUserRole(roleId) {
  return Roles.find(d => d.value == roleId);
}





//AUTHORIZATION
const setAuthToken = (token) => {
  //axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  localStorage.setItem(AUTH_TOKEN_KEY, token);
  //let sub = getTokenSub(token)
  //console.log(`sub: ${sub}`)
}

const getAuthToken = () => {
  return localStorage.getItem(AUTH_TOKEN_KEY)
}

const clearAuthToken = () => {
  console.log('Clearing auth token')
  //axios.defaults.headers.common['Authorization'] = ''
  localStorage.removeItem(AUTH_TOKEN_KEY)
}

const isLoggedIn = () => {
  let authToken = getAuthToken()
  let isLoggedIn = !!authToken && !isTokenExpired(authToken)
  console.log(`isLoggedIn: ${isLoggedIn}`)
  return isLoggedIn
}

const getUserInfo = () => {
  if (isLoggedIn()) {
    return decode(getAuthToken())
  }
}

function getTokenExpirationDate(encodedToken) {
  let token = decode(encodedToken)
  if (!token) {
    return null
  }

  let date = new Date(0)
  //date.setUTCSeconds(token.exp)

  return date
}

function isTokenExpired(token) {
  let expirationDate = getTokenExpirationDate(token)
  return expirationDate < new Date()
}
const UserService = {
  Login,
  VerifyLogin,
  GetUsers,
  CreateUser,
  GetUserRole,
  ChangePassword,
  Roles
};

export default UserService;