import http from "../http-common";
import { Project } from "src/models/project";

const Municipalities = [
    { value: 'Dr JS Moroka', label: 'Dr JS Moroka Municipality', src: '/static/images/avatars/moroka.png' },
    { value: 'Emakhazeni', label: 'Emakhazeni Municipality', src: '/static/images/avatars/victor.png' },
    { value: 'Emalahleni', label: 'Emalahleni Municipality', src: '/static/images/avatars/emalahleni.png' },
    { value: 'Nkangala', label: 'Nkangala District Municipality', src: '/static/images/avatars/nkangala.png' },
    { value: 'Steve Tshwete', label: 'Steve Tshwete Municipality', src: '/static/images/avatars/steve.png' },
    { value: 'Thembisile Hani', label: 'Thembisile Hani Municipality', src: '/static/images/avatars/victor.png' },
    { value: 'Victor Khanye', label: 'Victor Khanye Municipality', src: '/static/images/avatars/victor.png' }
];

const DistrictMunicipaity = { value: 'Nkangala', label: 'Nkangala District Municipality', src: '/static/images/avatars/nkangala.png' };

async function GetMunicipalityProjects(municipality_id: string): Promise<Array<Project>> {
    let projects = new Array<Project>();

    await http.get<Array<Project>>("/municipality?municipality_id=" + municipality_id).then((response: any) => {
        if (response.status == 200) {
            const myJSON = JSON.stringify(response.data);
            projects = JSON.parse(myJSON).projects;
        }
    }).catch((e: Error) => {
        console.log(e);
    });
    return projects;
};
const GetSelectedMunicipality = () => {
    let current_municipality = localStorage.getItem("SelectedMunicipality");
    return JSON.parse(current_municipality)?.value;
}

const SetSelectedMunicipality = (municipality: string) => {
    localStorage.setItem("SelectedMunicipality", municipality);
}
const MunicipalityService = {
    DistrictMunicipaity,
    Municipalities,
    GetMunicipalityProjects,
    GetSelectedMunicipality,
    SetSelectedMunicipality,
};

export default MunicipalityService;